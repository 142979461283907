import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { General, Generals } from '../../data-objects/units/Army';
import { ALL_GENERAL_SKILLS } from '../skills/GeneralSkillTree';
import UnitDetails from './UnitDetails';

interface Props {
  skilledGeneral: General | null;
}
function GeneralInfo({ skilledGeneral, ...otherProps }: Props) {
  const { t } = useTranslation();
  if (!skilledGeneral) {
    return <div {...otherProps} />;
  }

  const mySkills = skilledGeneral.skills;
  return (
    <div {...otherProps}>
      <div className='general-name'>{t(skilledGeneral.name)}</div>
      <UnitDetails unit={skilledGeneral} />
      <div className='general-skills'>
        {mySkills !== undefined &&
          mySkills !== null &&
          ALL_GENERAL_SKILLS.map(skill => {
            if ((mySkills[skill.attribute] ?? 0) > 0) {
              return <img key={skill.icon} src={skill.icon} alt='' />;
            }
            return null;
          })}
      </div>
      <div className='general-specials'>
        <div>
          {skilledGeneral.id === Generals.CHAMPION_NUSALA && t('Nusala-details')}
          {skilledGeneral.id === Generals.BROHMANN && t('Brohmann-details')}
          {skilledGeneral.id === Generals.VERMUMMTER_GENERAL && t('Vermummter-details')}
          {skilledGeneral.id === Generals.STEADFAST_GENERAL && t('Standhafte-details')}
          {skilledGeneral.id === Generals.JUNGER_GEMINI_GENERAL && t('Junger-details')}
          {skilledGeneral.id === Generals.GENERAL_BORIS && t('Boris-details')}
          {skilledGeneral.id === Generals.GENERAL_ZITTERBART && t('Zitterbart-details')}
          {(skilledGeneral.id === Generals.GEISTER_GENERAL || skilledGeneral.id === Generals.MIRACULOUS_GENERAL) &&
            t('Geistergeneral-details')}
          {skilledGeneral.id === Generals.FROST_GENERAL && t('Frostgeneral-details')}
          {skilledGeneral.id === Generals.RESOLUTE_GENERAL && t('Resolutegeneral-details')}
        </div>
        <div>
          {skilledGeneral.enemyAccuracyMalus &&
            t('enemy-accuracy-malus', {
              value: skilledGeneral.enemyAccuracyMalus
            })}
        </div>
        <div>{skilledGeneral.vargus && t('Vargus-details', { value: skilledGeneral.vargus })}</div>
        <div>{skilledGeneral.bonusHeavy && t('heavy-bonus-details', { value: skilledGeneral.bonusHeavy })}</div>
        <div>{skilledGeneral.bonusOff && t('offensive-bonus-details', { value: skilledGeneral.bonusOff })}</div>
        <div>{skilledGeneral.bonusDef && t('defensive-bonus-details', { value: skilledGeneral.bonusDef })}</div>
        <div>{skilledGeneral.bonusHp && t('bonus-hp-details', { value: skilledGeneral.bonusHp })}</div>
        <div>{skilledGeneral.bonusExp && t('bonus-ep-details', { value: skilledGeneral.bonusExp })}</div>
        <div>{skilledGeneral.recovery && t('recovery-details', { value: skilledGeneral.recovery })}</div>
        <div>{skilledGeneral.id === Generals.RESOLUTE_GENERAL && t('Resolutegeneral2-details')}</div>
        <div>{skilledGeneral.id === Generals.RESOLUTE_GENERAL && t('Resolutegeneral3-details')}</div>
      </div>
    </div>
  );
}

export default styled(GeneralInfo)`
  display: flex;
  flex-direction: column;
  width: 100%;

  .general-name {
    font-weight: 600;
  }

  .general-skills {
    display: flex;
    flex-wrap: wrap;

    & > img {
      width: 30px;
      height: 30px;
    }
  }

  ${UnitDetails}, .general-skills, .general-specials {
    padding: 8px 0 0 0;
    font-size: 0.8em;
  }
`;
